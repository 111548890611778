@import './../../../../../styles/main.scss';

.popular-video-tiles-container {
    margin: 0 5%;
    width: 90%;

    @include xl-desktop {
        width: 80%;
        margin: 0px 10% 0px 10%;
    }

    .popular-videos {
        height: 70px;
        color: $fds-color-dk-gray;
        text-align: center;
        margin-bottom: 44px;
        margin-top: 44px;

        .popular-video-title {
            line-height: 28px;
            margin-bottom: 16px;
            font-size: 26px;
            font-family: $f1-font-light;

            @include tablet {
                grid-template-columns: 1fr 1fr 1fr;
                font-size: 36px;
                line-height: 43px;
            }
        }
    }

    .tile-grid {
        display: grid;
        justify-content: center;
        grid-gap: 30px;
        grid-template-columns: 1fr;
        min-height: 850px;

        @include tablet {
            min-height: 180px;
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include desktop {
            min-height: 290px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include xl-desktop {
            grid-template-columns: 1fr 1fr 1fr;
            min-height: 400px;
            grid-gap: 75px;
        }
    }

    .popular-how-to-videos {
        width: 100%;
        color: $fds-color-dk-gray;
        margin-bottom: 44px;
        margin-top: 44px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $fds-color-dk-gray;

        .popular-how-to-videos-title {
            font-size: 18px;
            font-family: $f1-font-light;
            display: inline-flex;
            width: 80%;

            @include tablet {
                width: auto;
                font-size: 20px;
            }
        }

        .popular-videos-secondary-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $fds-color-primary;
            text-decoration: none;
            font-size: 1.125rem;
            line-height: 1.125rem;
            font-family: $f1-font-regular;
            transition: color 0.3s ease-in-out;
            border: none;
            background: transparent;
            padding: 0 10px;
            height: 40px;
            margin-top: 10px;

            @include mobile {
                color: $fds-color-primary;
                font-size: 0.8rem;
                line-height: normal;
                padding: 0;
                height: auto;
            }
        }

        .popular-videos-secondary-button-wrapper {
            @include mobile {
                .secondary-button-text {
                    display: none;
                }
            }
        }
    }
}
