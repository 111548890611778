@import './../../../../styles/main.scss';

//mobile
.upcoming-event-wapper {
    width: 90% !important;
    margin-top: 40px !important;
    margin-left: 5% !important;
    margin-right: 5% !important;

    //tablet and desktop
    @include tablet {
        width: 80% !important;
        margin-left: 10% !important;
        margin-right: 10% !important;

        .Player {
            min-width: 60% !important;
        }
    }

    .event-heading {
        height: 88px;
        color: #4d4d4d;
        font-family: $f1-font-light;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 30px;

        @include desktop {
            height: 58px;
            font-size: 36px;
            line-height: 44px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 58px;
        }
    }
}
