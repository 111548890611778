.brand-ford {
    .live-stream-view-wrapper {
        width: 100%;
        min-height: 50%;
        overflow: hidden;
        font-family: $f1-font-light;

        .live-stream-content-wrapper {
            background-color: $white;

            .smash-card {
                .smash-card__body {
                    .smash-card__body-title {
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-light;
                        font-size: 48px;
                        line-height: 58px;

                        @include tablet {
                            font-size: 34px;
                        }
                        @include desktop {
                            text-align: left;
                        }

                        @include xl-desktop {
                            font-size: 48px;
                            margin-left: 57px;
                        }
                    }
                    .smash-card__body-description {
                        height: 78px;
                        width: 313px;
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 26px;

                        @include xl-desktop {
                            height: 52px;
                            width: 476px;
                            margin-left: 57px;
                        }
                    }
                    .smash-card__body-cta {
                        margin: 41px 0 60px 0px;
                        @include xl-desktop {
                            margin: 36px 0 60px 57px;
                        }
                    }
                }
            }
        }
        .category-quiet-cards {
            max-width: 1933px;
            max-height: 450px;
            padding: 0;
            @include tablet() {
                margin-bottom: 200px;
            }
            @include desktop {
                margin-bottom: 0;
            }
            @include mobile() {
                margin-bottom: 300px;
            }
            .category-quiet-card__content-wrap {
                padding: 0;
                @include desktop() {
                    margin-top: 40px;
                }
                @include l-desktop() {
                    margin-top: 70px;
                }
                @include tablet-portrait() {
                    margin-top: 50px;
                }
            }
            .category-quiet-card__content-image {
                @include tablet-portrait() {
                    margin: 0;
                    max-width: 768px;
                }
                @include mobile-landscape() {
                    margin: 0;
                    max-width: 812px;
                }
            }
            .category-quiet-card__content-wrap {
                padding: 0 24px;
            }
            .category-quiet-card__content-wrap__title {
                text-align: left;
                margin-bottom: 30px;
                font-size: 48px;
                @include tablet() {
                    font-size: 34px;
                }
                @include xl-desktop() {
                    font-size: 48px;
                }
                @include tablet-portrait() {
                    text-align: center;
                }
                @include mobile-landscape() {
                    text-align: center;
                }
            }
            .category-quiet-card__content-wrap__body {
                text-align: left;
                @include tablet-portrait() {
                    text-align: center;
                }
                @include mobile-landscape() {
                    text-align: center;
                }
            }
            .category-quiet-card__content-wrap__cta {
                @include desktop() {
                    margin-left: 0;
                }
                @include l-desktop() {
                    margin-left: 0;
                }
                @include tablet-portrait() {
                    margin-left: 269px;
                }
                @include mobile() {
                    margin-left: 75px;
                }
            }
            .category-quiet-card__image-wrap {
                padding: 0;
            }
            .category-quiet-card__image-wrap__desktop-image {
                background-size: cover;
                background-position: unset;
            }
            .category-quiet-card__image-wrap__mobile-image {
                @include tablet-portrait() {
                    max-width: 400px;
                    margin-left: 185px;
                }
                @include mobile() {
                    max-width: 450px;
                    margin: 0;
                }
            }
        }
        .category-quiet-card {
            .category-quiet-card__content-image {
                margin: 0;
                max-width: 812px;
            }
        }
        .popular-video-wapper .popular-video-tile .video-poster {
            &:before {
                background-size: 100px;
                @include tablet {
                    background-size: 70px;
                }
                @include desktop {
                    background-size: 100px;
                }
            }

            &:hover:before {
                background-size: 120px;
                @include tablet {
                    background-size: 100px;
                }
                @include desktop {
                    background-size: 120px;
                }
            }
        }
    }
}
